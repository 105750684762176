import React from 'react';
import { Box, Divider } from '@chakra-ui/react';
import type { BoxProps } from '@chakra-ui/react';

const BlogContent = (props: BoxProps) => {
  return (
    <Box
      borderLeft={{ sm: '2px solid transparent', xl: '2px solid #716b9d' }}
      paddingTop={4}
      marginLeft={{ lg: 0, xl: 9 }}
      paddingBottom={10}
      paddingLeft={{ sm: 0, xl: 16 }}
      {...props}
    />
  );
};

const BlogContentSubHeading = ({ children, ...props }: BoxProps) => {
  return (
    <Box paddingBottom={4} {...props}>
      {children}
      <Divider borderColor="#afafaf" marginTop={8} marginBottom={6} />
    </Box>
  );
};

const BlogContentMain = ({ children, ...rest }: BoxProps) => {
  return <Box {...rest}>{children}</Box>;
};

export { BlogContent, BlogContentSubHeading, BlogContentMain };

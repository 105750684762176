import React from 'react';
import { Flex } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';

type Props = FlexProps;

const HighlightCard = ({ children, ...rest }: Props) => {
  return (
    <Flex
      flexDirection="column"
      background="linear-gradient(90deg, #716b9d 0%, #39487E 40%)"
      color="white"
      borderRadius="5px"
      padding={8}
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default HighlightCard;

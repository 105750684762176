
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { BlogPost } from '@/components/pages'
import { HighlightCard } from '@/components/ui'
import { BlogContent, BlogContentSubHeading, BlogContentMain } from '@/components/layout'
import generateMetaTags from '@/utils/meta';
export const meta = {
  title: 'Dev Journal 1',
  description: 'My very first journal entry to the blog!',
  date: 'Feb 01, 2022',
  readTime: '5 mins'
};

const layoutProps = {
  meta
};
const MDXLayout = ({ children }) => (<>
  {generateMetaTags({ title: meta.title })}
  <BlogPost meta={meta}>{children}</BlogPost>
</>)
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2><i>{`👋`}</i>{` Hello World`}</h2>
    <BlogContent mdxType="BlogContent">
      <BlogContentSubHeading mdxType="BlogContentSubHeading">
        <h3>{`My name is Cam Stark from Melbourne Australia.`}<br />{` web dev. dad. avid reader. and aspiring game developer.`}</h3>
      </BlogContentSubHeading>
      <BlogContentMain mdxType="BlogContentMain">
        <p>{`I only started coding 8 years ago, and interestingly .. used to wear a suite to work in my previous life as a Japanese businessman.
Back in 2014, I worked at a large Japanese trading firm acting as a
Japanese buyer's representative for Australian seller's woodchips for making paper. It was interesting work,
but I didn't have my heart in it.`}</p>
        <p>{`This corporate job was getting me depressed, and was slowly killing my ego. Further, I realized that in this hustle and bustle of the 'salaryman' - I had lost myself. I would eventually hang up the suite and close that chapter of my life, only to start a new chapter in web development.`}</p>
        <p>{`With help of family and friends I started my own small website business, spending 2 years creating Shopify and Wordpress websites, learning on the job. I lived and breathed this world of web development and my diligent focus later payed off getting work in the industry.
I have met some amazing people and worked on some very interesting projects at
various agencies and startups through out these years.`}</p>
        <p>{`... but I still reminisce the early web dev days where I was working day and
night to learn and create as much as possible. Nothing gets your motivation going as
starting your own business, learning as you go and trying to make some dosh to pay the rent.`}</p>
        <p>{`So I am channeling that feeling again to push in a new direction. Something that I have been wanting to do for a long time. I am diving into the world of game development.`}</p>
        <p>{`'Ghost Pixel' is a game development blog that I am creating to show my progress, learnings and projects.
It's here to keep me accountable, grounded and focused on my goal to become a game developer. I hope to be able to write interesting articles with my unique spin to it.`}</p>
        <p>{`In future, I would love this then to become something useful to other aspiring game makers and help them on their journey.`}</p>
      </BlogContentMain>
    </BlogContent>
    <h2><i>{`📄`}</i>{` My prev experience in game dev`}</h2>
    <BlogContent mdxType="BlogContent">
      <BlogContentMain mdxType="BlogContentMain">
        <p>{`Game dev experience level: `}<inlineCode parentName="p">{`Novice`}</inlineCode></p>
        <ul>
          <li parentName="ul">{`I have entered 1 game jam.`}</li>
          <li parentName="ul">{`I have made some tunes on Fruity Loops back in the day..(recently started to
learn a bit of Ableton)`}</li>
          <li parentName="ul">{`Created numerous game concepts`}</li>
          <li parentName="ul">{`Completed some game tutorials.`}</li>
        </ul>
        <p>{`And its a perfect place to start this blog!`}</p>
      </BlogContentMain>
    </BlogContent>
    <h2><i>{`📅`}</i>{` Plan for the year`}</h2>
    <BlogContent mdxType="BlogContent">
      <BlogContentSubHeading mdxType="BlogContentSubHeading">
        <p>{`I want to delve into the various aspects of game development: from
game design, coding, music production, game concepts, pixel art, character
design and of course enter some game jams.`}</p>
      </BlogContentSubHeading>
      <BlogContentMain mdxType="BlogContentMain">
        <HighlightCard mdxType="HighlightCard">
          <h3>{`3 goals this year`}</h3>
          <hr></hr>
          <ol>
            <li parentName="ol">{`Enter at least 4 game jams`}</li>
            <li parentName="ol">{`Write a dev journal blog post every week`}</li>
            <li parentName="ol">{`Monthly deep dive post on a given topic`}</li>
          </ol>
        </HighlightCard>
      </BlogContentMain>
    </BlogContent>
    <h2><i>{`🍻`}</i>{`Cheers`}</h2>
    <BlogContent mdxType="BlogContent">
      <p>{`I look forward to learning, creating new concepts, failing, succeeding. but most importantly enjoying the journey!`}</p>
      <p>{`Please come visit here again soon ♥️`}</p>
      <p>{`I will be posting on my twitter account for any updates.`}</p>
    </BlogContent>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
function importAll(r) {
  return r.keys().map((fileName) => ({
    link: fileName.substr(1).replace(/\/index\.mdx$/, ''),
    module: r(fileName),
  }));
}

export const posts = importAll(
  //have to add regex to remove duplicate file paths with ^(.\/).
  //https://github.com/ryanjwessel/ryanjwessel.com/commit/1390607508a13a66964ffb484ff0950d7114094e
  require.context('../pages/blog/', true, /^(.\/).*(.mdx)$/)
);

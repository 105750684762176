import React from 'react';
import {
  Container,
  Grid,
  GridItem,
  Flex,
  VStack,
  Box,
  Stack,
  Text,
  Image,
} from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';

import { Page } from '@/components/layout';
import { posts } from '@/getAllPosts';
import { Post } from '@/components/ui';
import logoSVG from '@/images/logo.svg';

import type { PostProps } from '@/components/ui';

type Props = FlexProps;

const Home = ({ ...rest }: Props) => {
  return (
    <Page {...rest}>
      <Flex
        borderTop="20px solid #dfdfdf"
        background="linear-gradient(332deg, rgb(32 32 32 / 70%) 52%, rgb(10 10 10 / 70%) 52%),linear-gradient(30deg, rgb(0 0 0 / 70%) 52%, rgb(114 114 114 / 70%) 52%),linear-gradient(270deg, rgb(0 0 0 / 70%) 50%, rgb(245 245 245 / 70%) 50%),linear-gradient(1deg, rgb(0 0 0 / 0%) 52%, rgb(120 120 120 / 70%) 52%)"
      >
        <Flex marginX="auto" justifyItems="center">
          <Container maxWidth="container.xl">
            <Stack
              spacing={{ base: 0, md: 12 }}
              textAlign="center"
              width="100%"
              color="white"
              padding={{ base: '40px 0', md: '100px 0 120px 0' }}
            >
              <Text
                fontSize="20px"
                borderBottom="1px solid #dfdfdf"
                paddingBottom="30px"
              >
                Since 2022
              </Text>
              <Text
                as="h1"
                display="flex"
                alignItems="center"
                fontSize={{ base: '26vw', sm: '70px', lg: '100px' }}
                flexDirection={{ base: 'column', md: 'row' }}
              >
                GHOST
                <Box display="inline-flex" marginX={2}>
                  <Image
                    src={logoSVG.src}
                    alt=""
                    width={'180px'}
                    height={'180px'}
                    flexShrink={0}
                  />
                </Box>
                PIXEL
              </Text>
              <VStack borderTop="1px solid #dfdfdf" padding={8}>
                <Text fontSize="20px">an aspiring game dev&apos;s blog</Text>
              </VStack>
            </Stack>
          </Container>
        </Flex>
      </Flex>
      <Stack
        marginX="auto"
        padding={4}
        spacing={4}
        textAlign="left"
        width="100%"
        background="#dfdfdf"
        borderTop="3px solid #333"
      >
        <Container maxWidth="container.xl">
          <Box direction="column" paddingY={8}>
            <Text as="h2" fontSize="40px" marginBottom={8}>
              Posts
            </Text>
            <Grid
              templateColumns={{ md: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }}
              gap={6}
              color="white"
            >
              {posts.map((post: PostProps, key: number) => {
                const { link, module } = post;
                return (
                  <GridItem key={`${link}${key}`}>
                    <Post link={link} module={module} />
                  </GridItem>
                );
              })}
            </Grid>
          </Box>
        </Container>
      </Stack>
    </Page>
  );
};

export default Home;

import React from 'react';
import { Flex, Box, Container, Stack, Text } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';
import { Page } from '@/components/layout';
import logoSVG from '@/images/logo.svg';

export type BlogPostProps = {
  meta: {
    title: string;
    date: string;
    readTime: string;
  };
  link: string;
};

const BlogPost = ({ children, ...rest }: BlogPostProps & FlexProps) => {
  const {
    meta: { title, date, readTime },
  } = rest;
  return (
    <Page {...rest}>
      <Flex
        borderTop="20px solid #dfdfdf"
        background="linear-gradient(332deg, rgb(32 32 32 / 70%) 52%, rgb(10 10 10 / 70%) 52%),linear-gradient(30deg, rgb(0 0 0 / 70%) 52%, rgb(114 114 114 / 70%) 52%),linear-gradient(270deg, rgb(0 0 0 / 70%) 50%, rgb(245 245 245 / 70%) 50%),linear-gradient(1deg, rgb(0 0 0 / 0%) 52%, rgb(120 120 120 / 70%) 52%)"
        width="100%"
        height="100%"
        minHeight={{ base: '500px', md: '650px' }}
      >
        <Flex
          width="100%"
          backgroundImage={logoSVG.src}
          backgroundSize="180px 180px"
          backgroundRepeat="no-repeat"
          backgroundPosition="center center"
          filter="grayscale(100%)"
          alignItems="flex-end"
          top="-30px"
          position="relative"
        >
          <Container maxWidth="container.xl">
            <Stack spacing={1} marginBottom={5} color="white">
              <Text as="h1" fontSize={{ base: '30px', md: '50px' }}>
                {title}
              </Text>
              <Text>{date}</Text>
              <Text>Reading time: {readTime}</Text>
            </Stack>
          </Container>
        </Flex>
      </Flex>
      <Flex backgroundColor="#dfdfdf">
        <Container maxWidth="container.xl">
          <Flex
            flexDirection="column"
            padding={{ base: '60px 0', md: '60px 0 120px 0' }}
            justifyContent="flexStart"
            maxWidth="900px"
          >
            <Box>{children}</Box>
          </Flex>
        </Container>
      </Flex>
    </Page>
  );
};

export default BlogPost;

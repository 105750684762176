import React from 'react';
import NextLink from 'next/link';
import { Container, Flex, Icon, Link, Stack, Image } from '@chakra-ui/react';
import type { FlexProps, LinkProps } from '@chakra-ui/react';

import { TwitterIcon } from '@/theme/icons';
import LogoIcon from '@/images/logo-icon.svg';

type SocialLinkProps = {
  alt: string;
  href: string;
} & LinkProps;

const SocialLink = ({ alt, href, children, ...rest }: SocialLinkProps) => {
  return (
    <Link
      aria-label={alt}
      href={href}
      isExternal
      lineHeight={1}
      padding={3}
      _hover={{ color: 'gray.400' }}
      {...rest}
    >
      {children}
    </Link>
  );
};

type HeaderProps = FlexProps;

const Header = ({ ...rest }: HeaderProps) => {
  return (
    <>
      <Flex
        as="header"
        direction="column"
        background="#171717"
        flexShrink={0}
        justifyContent="center"
        width="100%"
        position="sticky"
        zIndex={100}
        top={0}
        {...rest}
      >
        <Container maxWidth="container.xl">
          <Flex
            alignItems="center"
            direction={'row'}
            justifyContent="space-between"
            paddingY={4}
            width="100%"
          >
            <NextLink href="/" passHref>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                display="flex"
                justifyContent="center"
                alignItems="center"
                _hover={{ textDecoration: 'none' }}
              >
                <Image
                  alt=""
                  src={LogoIcon.src}
                  height={16}
                  width={16}
                  marginX={1}
                />
              </Link>
            </NextLink>
            <Stack
              color="white"
              direction="row"
              fontSize="2xl"
              marginTop={[2, 0]}
              spacing={2}
            >
              <SocialLink
                alt="Visit Ghost Pixel Blog's Twitter page"
                href="https://twitter.com/ghostpixelblog"
              >
                <Icon as={TwitterIcon} />
              </SocialLink>
            </Stack>
          </Flex>
        </Container>
      </Flex>
    </>
  );
};

export default Header;

import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';
import { Link } from '@/theme/chakra-elements';

export type PostProps = {
  link: string;
  module: {
    meta: {
      title: string;
      description: string;
      date: string;
      readTime: number;
    };
  };
};

const Post = ({ link, module: { meta }, ...rest }: PostProps & FlexProps) => {
  const { title, description, readTime, date } = meta;
  return (
    <Flex
      flexDirection={{ base: 'column', md: 'row' }}
      position="relative"
      background="#3b3759"
      minHeight="100px"
      padding="40px 30px"
      justifyContent="space-between"
      borderRadius="10px"
      {...rest}
    >
      <Flex flex={1} flexDirection="column">
        <Text
          as="h3"
          fontSize="28px"
          marginTop={0}
          marginBottom={4}
          color="white"
        >
          {title}
        </Text>
        <Text>{date}</Text>
        <Text>Read Time: {readTime}</Text>
      </Flex>
      <Flex
        flexDirection="column"
        width={{ sm: 'auto', md: '240px' }}
        justifyContent="flex-end"
        alignItems={'space-between'}
      >
        <Text minHeight="100px">{description}</Text>
        <Link
          alignSelf="flex-end"
          href={`blog${link}`}
          _hover={{ textDecoration: 'none', backgroundColor: '#5779aa' }}
          backgroundColor="#716b9d"
          padding="10px"
          boxShadow="2px 2px 2px #3b3759"
          transition="background-color 0.3s ease-in-out"
          borderRadius="5px"
        >
          Read More
        </Link>
      </Flex>
    </Flex>
  );
};

export default Post;
